
























































































































































































import SfFooter from '/storefrontUI/components/components/organisms/SfFooter/SfFooter.vue';
import SfList from '/storefrontUI/components/components/organisms/SfList/SfList.vue';
import SfImage from '/storefrontUI/components/components/atoms/SfImage/SfImage.vue';
import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfLink from '/storefrontUI/components/components/atoms/SfLink/SfLink.vue';
import SfMenuItem from '/storefrontUI/components/components/molecules/SfMenuItem/SfMenuItem.vue';
import {defineComponent} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfFooter,
    SfList,
    SfImage,
    SfMenuItem,
    SfButton,
    SfLink,
  },
  setup() {
    return {
      addBasePath,
    };
  },
  data() {
    return {
      categories: [
        {
          label: 'Uomo',
          link: '/it/uomo'
        },
        {
          label: 'Donna',
          link: '/it/donna'
        },
        {
          label: 'Brand',
          link: '/it/brand'
        },
        {
          label: 'Newsletter',
          link: '/it/newsletter'
        }
      ],
      need: [
        {
          label: 'Chi siamo',
          category: '',
          link: '/it/azienda'
        },
        {
          label: 'Contattaci',
          category: '',
          link: '/it/assistenza'
        },
      ],
      ordini: [
        {
          label: 'Resi e Cambi',
          link: '/it/resi'
        },
        {
          label: 'Metodi di pagamento',
          link: '/it/metodi-di-pagamento'
        },
        {
          label: 'Spedizioni',
          link: '/it/spedizioni'
        },
        {
          label: 'Servizio Clienti',
          link: '/it/assistenza'
        },
        {
          label: 'Condizioni di vendita',
          link: '/it/condizioni-di-vendita'
        },
        {
          label: 'Siti truffa',
          link: '/it/siti-truffa'
        },
      ],
      social: [
        {
          img: 'facebook',
          link: 'https://www.facebook.com/need.it.s.all.you.need/friends_likes/',
        },
        {
          img: 'instagram',
          link: 'https://www.instagram.com/need_italia/',
        }
      ],
      isMobile: false,
      desktopMin: 1024,
    };
  },
});
